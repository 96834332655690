<template>
  <form @submit.prevent="addUser">
    <a-row :gutter="0" v-if="!readonly" :style="{ flexWrap: 'nowrap' }">
      <a-col flex="auto">
        <a-input size="large" v-model:value="inputValue" type="email" placeholder="Введите email"/>
      </a-col>
      <a-col flex="110px">
        <a-button size="large" block html-type="submit" :disabled="!inputValue">Добавить</a-button>
      </a-col>
    </a-row>
    <div class="user-list" v-if="!readonly || userList.length > 0">
      <a-row type="flex" justify="space-between" align="middle" v-for="(email, i) in userList" :key="i"
             :style="{ marginTop: '1rem' }">
        <a-col>
          <a-avatar :style="{ marginRight: '0.5rem' }">
            <template #icon>
              <user-outlined/>
            </template>
          </a-avatar>
          <span class="user-name">
            {{ email }}
          </span>
        </a-col>
        <a-col class="text-right" v-if="!readonly">
          <a-button type="dashed" @click="deleteUserHandler(i)">
            <template #icon>
              <close-outlined/>
            </template>
          </a-button>
        </a-col>
      </a-row>
    </div>
    <p v-else class="disabled-color-text">Участников нет</p>
  </form>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { unref } from "@vue/runtime-core";
import {
  UserOutlined,
  CloseOutlined,
} from '@ant-design/icons-vue';
import { notification } from "ant-design-vue";

export default {
  name: 'InviteUserList',
  props: {
    list: Array,
    readonly: Boolean,
  },
  emits: ['change', 'add'],
  setup(props, { emit }) {
    const userList = computed(() => props.list);
    const inputValue = ref('');

    function addUser() {
      if (unref(inputValue).length < 1) return;
      if (unref(userList).indexOf(unref(inputValue)) !== -1) {
        inputValue.value = '';
        return notification.info({
          message: 'Адрес уже добавлен',
          description: 'В списке уже есть такой email',
        });
      }

      const emailRegex = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i;
      if (!emailRegex.test(unref(inputValue))) {
        return notification.info({
          message: 'Проверьте адрес',
          description: 'Неправильный формат email-адреса',
        });
      } else {
        userList.value.push(unref(inputValue));
        inputValue.value = '';
        emit('add', unref(inputValue));
        emit('change', unref(userList));
      }
    }

    function deleteUserHandler(index) {
      userList.value.splice(index, 1);
      emit('change', unref(userList));
    }

    return {
      userList, inputValue,
      addUser, deleteUserHandler
    }
  },
  components: {
    UserOutlined,
    CloseOutlined,
  }
}
</script>
