import Axios from "axios";
import { reactive, ref } from "@vue/reactivity";
import { store } from '@/store'
import { urlHelper } from "@/compositions/commonFunctions";

// API запросы
export function requestAPI() {

  // Получение списка типов
  async function fetchListUsers(params) {
    try {
      return await Axios.get(`/users` + urlHelper.makeGetParams(params));
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  // Получение списка групп
  async function fetchListGroups(params) {
    try {
      return await Axios.get(`/groups` + urlHelper.makeGetParams(params));
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  return { fetchListUsers, fetchListGroups }
}

export function getUserListData() {
  const loading = ref(false);

  const data = ref([]);
  const pagination = reactive({ hideOnSinglePage: true });
  const { fetchListUsers } = requestAPI();

  async function fetchUserList(search) {
    try {
      loading.value = true;
      const res = await fetchListUsers({
        page: pagination.current,
        search,
      });
      //
      data.value = res.data.map((item) => ({
        ...item,
        name: [item.lastName, item.firstName, item.attributes?.middleName].join(' ')
      }));
      pagination.pageSize = +res.meta.perPage;
      pagination.total = +res.meta.total;
      pagination.current = +res.meta.currentPage;
    } catch (e) {
      console.error(e);
    } finally {
      loading.value = false;
    }
  }

  return {
    loading,
    data, pagination,
    fetchUserList,
  };
}

export function getGroupListData() {
  const loading = ref(false);

  const data = ref([]);
  const pagination = reactive({ hideOnSinglePage: true });
  const { fetchListGroups } = requestAPI();

  async function fetchGroupList(search) {
    try {
      loading.value = true;
      const res = await fetchListGroups({
        page: pagination.current,
        search,
      });
      //
      data.value = res.data;
      pagination.pageSize = +res.meta.perPage;
      pagination.total = +res.meta.total;
      pagination.current = +res.meta.currentPage;
    } catch (e) {
      console.error(e);
    } finally {
      loading.value = false;
    }
  }

  return {
    loading,
    data, pagination,
    fetchGroupList,
  };
}
