<template>
  <section>
    <div class="reservation-preview">
      <app-image :src="previewUrl" :width="previewSize" :height="previewSize"/>
      <div class="description">
        <div>
          <h6>
            <router-link class="text-color-text" :to="{ name: 'objectView', params: { id: objectId } }" v-if="objectId">
              {{ name }}
            </router-link>
            <span v-else>{{ name }}</span>
          </h6>
          <p class="paragraph-5 grayscale-gray-color-text">Код объекта: {{ code }} </p>
        </div>
        <div>
          <slot name="buttons"></slot>
        </div>
      </div>
    </div>

    <a-descriptions bordered :column="1" size="small" v-if="showReservationInfo">
      <a-descriptions-item label="Локация">
        {{ renderObjectPath(reservation?.object?.path || [], false, ', ') }}
      </a-descriptions-item>
      <a-descriptions-item label="Начало">
        {{ moment(reservation?.timeFrom, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY | HH:mm') }}
      </a-descriptions-item>
      <a-descriptions-item label="Окончание">
        {{ moment(reservation?.timeTo, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY | HH:mm') }}
      </a-descriptions-item>
    </a-descriptions>

  </section>
</template>

<script>
import { getOneData as getOneObject } from "@/compositions/objects";
import AppImage from "../shared/ul/AppImage";
import moment from "moment";

export default {
  name: 'ReservationPreview',
  props: {
    name: String,
    code: String,
    previewUrl: String,
    previewSize: { type: String, default: '90px' },
    objectId: String,
    reservation: Object,
    showReservationInfo: Boolean,
  },
  setup() {
    const { renderObjectPath } = getOneObject();
    return { moment, renderObjectPath };
  },
  components: { AppImage },
}
</script>

<style lang="less">
.reservation-preview {

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;

  .description {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    padding-left: 1.25rem;
  }
}
</style>
