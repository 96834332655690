import { computed, ref } from "@vue/reactivity";
import { unref } from "@vue/runtime-core";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { Modal } from 'ant-design-vue';

export function keepUnsavedChangesManager() {
  const router = useRouter();

  // Формы, статус которых контролируется
  const formsCurrent = ref({}); // { key: form, ... }
  const formsSaved = ref({}); // { key: formHash}
  const formsSavedHash = ref({}); // { key: formHash}
  const onlyFormChecking = ref(false); // don't use beforeUnload hook
  let stayPageCallback = null;

  const allowLeaveSilent = ref(false); // allow leave page with no confirmation (eq. saving before, but without)

  function useKeepManager(formsToWatch, handleKeepChanges, silent = false) {
    //
    formsCurrent.value = formsToWatch;
    stayPageCallback = handleKeepChanges;
    onlyFormChecking.value = silent;

    fixChanges();
    console.log('Using keep manager');
  }

  function fixChanges(key) {
    if (key) {
      formsSaved.value[key] = { ...unref(formsCurrent)[key] };
      formsSavedHash.value[key] = JSON.stringify(unref(formsCurrent)[key]);
    } else {
      // Зафиксировать состояния всех форм
      for (const [key, formState] of Object.entries(formsCurrent.value)) {
        formsSaved.value[key] = { ...formState };
        formsSavedHash.value[key] = JSON.stringify(formState);
      }
    }
  }

  function formCurrentHash(key) {
    return JSON.stringify(unref(formsCurrent)[key])
  }

  function isFormChanged(key) {
    return formCurrentHash(key) !== unref(formsSavedHash)[key];
  }

  const changedFormsList = computed(() => {
    let changedFormKeys = [];
    // Проверить состояния всех форм
    for (const key of Object.keys(unref(formsCurrent))) {
      console.log(key);
      if (isFormChanged(key)) changedFormKeys.push(key);
    }
    return changedFormKeys;
  });

  const isOneOfFormsChanged = computed(() => {
    return unref(changedFormsList).length > 0;
  });

  onBeforeRouteLeave((to) => {
    if (unref(onlyFormChecking)) return true;
    if (unref(isOneOfFormsChanged) && !unref(allowLeaveSilent)) {

      Modal.confirm({
        title: 'Данные не сохранены',
        content: 'Вы уверены, что хотите покинуть эту страницу? Несохранённые изменения будут удалены.',
        okText: 'Остаться',
        cancelText: 'Покинуть',
        onOk: () => {
          console.log('Остаёмся.')
          if (stayPageCallback) {
            stayPageCallback(unref(changedFormsList));
          }
        },
        onCancel: () => {
          allowLeaveSilent.value = true;
          router.push(to);
        }
      });

      return false;

    } else
      return true;
  })

  return { useKeepManager, fixChanges, isFormChanged, isOneOfFormsChanged, formCurrentHash, formsSavedHash, formsSaved }
}
