<template>
  <div>
    <div class="preview-img" :class="{ loaded: isImgLoaded, 'has-image': previewUrl }"
         :style="{
            width, height, flexBasis: width,
            backgroundImage: previewUrl ? `url('${previewUrl}')` : `url('/images/placeholder.svg')`
         }">
    </div>
    <img :src="previewUrl" @load="isImgLoaded = true" alt="preview" :style="{ display: 'none' }">
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

export default {
  name: 'AppImage',
  props: {
    src: String,
    width: { type: String, default: '90px' },
    height: { type: String, default: '90px' },
  },
  setup(props) {
    const isImgLoaded = ref(false);
    const previewUrl = computed(() => props.src);

    watch(previewUrl, () => {
      isImgLoaded.value = false;
    });

    return { isImgLoaded, previewUrl };
  },
}
</script>

<style lang="less">
.preview-img {
  border-radius: 2 * @border-radius-base;
  background-color: @background-primary;
  position: relative;
  flex-shrink: 0;
  background-size: 64px;
  background-repeat: no-repeat;
  background-position: center;

  &.has-image {
    transition: 0.3s ease-in-out;
    opacity: 0.3;
    background-size: cover;

    &.loaded {
      opacity: 1;
    }
  }
}
</style>
