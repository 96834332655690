<template>
  <section>
    <a-page-header :title="pageTitle" @back="() => $router.push({name: 'reservations'})"
                   :class="{ loading }">
      <template #extra v-if="!readonly">
        <a-button key="cancel" size="large" @click="() => $router.push({name: 'reservations'})">
          Отменить
        </a-button>
        <a-button key="save" size="large" type="primary"
                  :loading="loadingProgress" :disabled="!isOneOfFormsChanged"
                  @click="handleSubmit">
          Сохранить
        </a-button>
      </template>
    </a-page-header>
    <a-skeleton :loading="loading">
      <a-form layout="vertical" ref="formInfoRef" :model="formInfo" :style="{ maxWidth: '400px' }">

        <a-form-item label="Начало">
          <a-row :gutter="16">
            <a-col :span="12">
              <a-date-picker v-model:value="formSearchDate.dateFrom" placeholder="Дата" size="large"
                             :disabled="readonly || formInfo.statusOnLoad === 'in_use'"
                             @change="dateChanged('dateFrom')"
                             class="w-100" format="DD.MM.YY" value-format="YYYY-MM-DD"/>
            </a-col>
            <a-col :span="12">
              <smart-time-field v-model="formSearchDate.timeFrom" v-model:valid="formSearchDate.timeFromValid"
                                @change="timeChanged('timeFrom')"
                                :disabled="readonly || formInfo.statusOnLoad === 'in_use'"/>
            </a-col>
          </a-row>
        </a-form-item>

        <a-form-item label="Окончание">
          <a-row :gutter="16">
            <a-col :span="12">
              <a-date-picker v-model:value="formSearchDate.dateTo" placeholder="Дата" size="large" :disabled="readonly"
                             @change="dateChanged('dateTo')"
                             :disabled-date="disabledDateTo"
                             class="w-100" format="DD.MM.YY" value-format="YYYY-MM-DD"/>
            </a-col>
            <a-col :span="12">
              <smart-time-field v-model="formSearchDate.timeTo" v-model:valid="formSearchDate.timeToValid"
                                @change="timeChanged('timeFrom')"
                                :disabled="readonly"/>
            </a-col>
          </a-row>
        </a-form-item>

        <a-form-item label="Пользователь">
          <a-select show-search
                    size="large" v-model:value="formInfo.userId" placeholder="Выберите пользователя"
                    :loading="userLoading"
                    :default-active-first-option="false"
                    :disabled="readonly || formInfo.statusOnLoad === 'in_use'"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="fetchUserList"
          >
            <a-select-option v-for="user in userList" :value="user.id" :disabled="user.disabled"
                             :key="user.id">{{ user.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Локация">
          <span v-for="(pathItem, index) in formInfo.object?.path" :key="pathItem.id">
            <router-link class="text-color-text" v-if="pathItem.name === 'root'" :to="{ name: 'objects' }">Верхний уровень</router-link>
            <router-link class="text-color-text" v-else
                         :to="{ name: 'objectChildren', params: { id: pathItem?.id } }">
              {{ pathItem.name }}
            </router-link>
            <span v-if="index < formInfo.object?.path?.length - 1"> &gt; </span>
          </span>
        </a-form-item>

        <a-form-item label="Объект бронирования">
          <reservation-preview :name="formInfo.object?.name" :code="formInfo.object?.code"
                               :object-id="formInfo.object?.id"
                               :preview-url="formInfo.object?.previewUrl"/>
        </a-form-item>

        <a-form-item label="Статус">
          <a-select v-model:value="formInfo.status" :default-active-first-option="false"
                    size="large" placeholder="Выберите статус"
                    :show-arrow="true"
                    :disabled="readonly"
                    :filter-option="false"
                    :not-found-content="null">
            <a-select-option v-for="(option, key) in reservationStatusOptions" :key="key" :value="option.value">
              {{ option.text }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Участники" v-if="formInfo?.object?.userInvitationAvailable">
          <invite-user-list :list="formInfo.invitedUsers" :readonly="readonly"/>
        </a-form-item>

      </a-form>
    </a-skeleton>
  </section>
</template>

<script>
import { ref } from "@vue/reactivity";
import { getListData as getReservationListData, requestAPI as reservationAPI } from "@/compositions/reservations";
import { Modal, notification } from "ant-design-vue";
import { onMounted, unref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { urlHelper, dateHelper } from "@/compositions/commonFunctions";
import { getOneData as getOneObject } from "@/compositions/objects";
import moment from "moment";
import ReservationPreview from "@/components/admin/reservation/ReservationPreview";
import SmartTimeField from "@/components/admin/shared/ul/SmartTimeField";
import InviteUserList from "@/components/admin/reservation/InviteUserList";
import { getUserListData } from "@/compositions/userAndGroups";
import { keepUnsavedChangesManager } from "@/compositions/keepUnsavedChangesManager";

export default {
  name: "ReservationEditPage",
  setup() {
    const route = useRoute();
    const router = useRouter();

    const loading = ref(true);
    const loadingProgress = ref(false);
    const pageTitle = ref('Бронирование');

    // Форма отображения
    const formInfoRef = ref(null);
    const formInfo = ref({
      id: undefined,
      user: undefined,
      userId: undefined, // для отправки обратно
      object: undefined,
      status: undefined,
      statusOnLoad: undefined,
      invitedUsers: [],
    });

    const {
      RESERVATION_STATUS,
      formSearchDate, disabledDateTo, dateChanged, timeChanged
    } = getReservationListData();
    const { getReservationById, updateReservation, removeReservation } = reservationAPI();
    const { renderObjectPath } = getOneObject();
    const { loading: userLoading, fetchUserList, data: userList } = getUserListData();
    const { useKeepManager, fixChanges, isFormChanged, isOneOfFormsChanged } = keepUnsavedChangesManager();

    const readonly = ref(true);
    const reservationStatusOptions = ref([]);

    onMounted(async () => {
      await loadData();
    });

    async function loadData() {
      loading.value = true;
      try {
        const res = await getReservationById(route.params.id);
        if (res?.id) {
          const { timeFrom, timeTo } = res;
          formInfo.value = res;
          formInfo.value.userId = formInfo.value?.user?.id;
          formInfo.value.statusOnLoad = res?.status;
          // Разделяем дату на дату и время
          formSearchDate.dateFrom = moment(timeFrom, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
          formSearchDate.dateTo = moment(timeTo, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
          formSearchDate.timeFrom = moment(timeFrom, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm');
          formSearchDate.timeTo = moment(timeTo, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm');

          pageTitle.value = `Бронь объекта ${formInfo.value.object?.name}`;
          urlHelper.setPageTitle(pageTitle.value);
          useKeepManager({ 'info': formInfo, 'date': formSearchDate });

          reservationStatusOptions.value = [];
          readonly.value = false;
          if (res?.status === 'reserved') {
            reservationStatusOptions.value = [RESERVATION_STATUS.reserved, RESERVATION_STATUS.free];
            if (res?.canStart) {
              reservationStatusOptions.value.push(RESERVATION_STATUS.in_use);
            }
          }
          if (res?.status === 'in_use') {
            reservationStatusOptions.value = [RESERVATION_STATUS.free, RESERVATION_STATUS.in_use, RESERVATION_STATUS.complete];
          }
          if (res?.status === 'complete') {
            reservationStatusOptions.value = [RESERVATION_STATUS.complete];
            readonly.value = true;
          }

          await fetchUserList();
          if (formInfo.value?.user && !userList.value.find((option) => option.id === formInfo.value?.user?.id)) userList.value.unshift(formInfo.value?.user);
        } else {
          notification.error({
            message: 'Ошибка',
            description: 'Бронирование не найдено',
          });
          router.push({ name: 'reservations' });
        }
      } catch (e) {
        router.push({ name: 'reservations' });

      } finally {
        loading.value = false;
      }
    }

    async function handleSubmit() {
      loadingProgress.value = true;
      try {
        if (formInfo.value.status === 'free') {
          Modal.confirm({
            title: 'Удалить бронирование?',
            okText: 'Удалить',
            okType: 'danger',
            cancelText: 'Отмена',
            async onOk() {
              fixChanges();
              await removeReservation(route.params.id);
              notification.success({
                message: 'Успех',
                description: 'Бронирование удалено',
              });
              router.push({ name: 'reservations' });
            }
          });
        } else {
          const { dateFrom, dateTo, timeFrom, timeTo, } = unref(formSearchDate);
          const { object, userId, invitedUsers, status } = unref(formInfo);

          if (!formSearchDate.timeFromValid || !formSearchDate.timeToValid) {
            return notification.error({
              message: 'Ошибка',
              description: 'Установите корректное время бронирования',
            });
          }
          if (!dateFrom || !dateTo || !timeFrom || !timeTo) {
            return notification.error({
              message: 'Ошибка',
              description: 'Установите даты бронирования',
            });
          }

          const dateFromFull = moment(`${dateFrom} ${timeFrom}`, 'YYYY-MM-DD HH:mm');
          const dateToFull = moment(`${dateTo} ${timeTo}`, 'YYYY-MM-DD HH:mm');

          if (!dateFromFull.isValid() || !dateToFull.isValid() || dateFromFull >= dateToFull) {
            return notification.error({
              message: 'Ошибка',
              description: 'Проверьте, правильно ли выбраны даты',
            });
          }

          await formInfoRef.value
              .validate()
              .then(async () => {
                    await updateReservation({
                      objectId: object?.id,
                      userId,
                      status,
                      invitedUsers,
                      dateFrom: dateFromFull.format('YYYY-MM-DD HH:mm:00'),
                      dateTo: dateToFull.format('YYYY-MM-DD HH:mm:00'),
                    }, route.params.id);
                    notification.success({
                      message: 'Успех',
                      description: 'Парамеры бронирования сохранены',
                    });
                    fixChanges();
                    loadData();
                  }
              );
        }

      } catch (e) {
        console.error(e);
      } finally {
        loadingProgress.value = false;
      }
    }

    return {
      dateHelper, readonly, isFormChanged, isOneOfFormsChanged,
      loading, loadingProgress,
      formInfo, formInfoRef,
      pageTitle, renderObjectPath,
      reservationStatusOptions,
      userLoading, fetchUserList, userList,
      handleSubmit,
      formSearchDate, disabledDateTo, dateChanged, timeChanged,
    }
  },
  components: { InviteUserList, SmartTimeField, ReservationPreview }
}
</script>
